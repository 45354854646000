import React, { useEffect, useState } from "react";
// import axios from "../../../lib/axios";
import ViewStudent from "./AdminViewTest";
import "./AdTest.scss";
import { Delete } from "@mui/icons-material";
import TechAddLesson from "./AdminAddTest";
import TechUpdateLesson from "./AdminUpdateTest";
import AdNavbar from "../AdNavbar/AdNavbar";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "../../../lib/axios";

export default function AdTest() {
  const useAuth = window.localStorage;
  const Token = useAuth.getItem("accessToken");
  const [tests, setTests] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true); // Loading holati
  const limit = 10;

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    const fetchData = async() => {
      setLoading(true); // Ma`lumot yuklanmoqda deb belgilaymiz
      try {
        const response = await axios.get(
          `base-tests?page=${page}&limit=${limit}`,
          config
        );
        setTests(response.data.tests || []);
        setTotalPages(
          response.data.count < limit ? 1 : Math.ceil(response.data.count / limit)
        );
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false); // Ma`lumot yuklanib bo'ldi
      }
    };
    fetchData();
  }, [page]);

  const deleteLesson = async(id) => {
    if (window.confirm("O'chirishni istaysizmi?")) {
      try {
        await axios.delete(`base-test/${id}`, config);
        setTests((prev) => prev.filter((test) => test.id !== id));
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <AdNavbar />
      <main className="mainPage">
        <section className="mygroup-section tech-lesson-section ad-teacher">
          <div className="container">
            <div className="mygroup">
                  <table className="mygroup__table desktop">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Test nomi</th>
                        <th>Yuqori ball</th>
                        <th>Sana</th>
                        <th className="add-wrapper">
                          <TechAddLesson />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tests.length === 0 ? (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center" }}>
                          {loading ? "Yuklanmoqda..." : " Ma`lumotlar mavjud emas "}
                          </td>
                        </tr>
                      ) : (
                        tests.map((item, index) => (
                          <tr key={index} id={item.id}>
                            <td>{index + 1}</td>
                            <td id="lessonName">
                              <Link to={`/ad-tests/${item.base_test_id}`}>
                                {item.name}
                              </Link>
                            </td>
                            <td>{item.max_point}</td>
                            <td>{item.date && item.date.slice(0, 10)}</td>
                            <td className="add-wrapper">
                              <TechUpdateLesson data={item} />
                              <button
                                className="btn-delete"
                                onClick={() => deleteLesson(item.base_test_id)}
                                style={{ color: "crimson" }}
                              >
                                <Delete />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>

                  <div className="mygroup__table mobile">
                    <div className="mygroup__table-head">
                      <div className="">#</div>
                      <div className="">Test haqida</div>
                      <TechAddLesson />
                    </div>
                    <div className="mygroup__table-body">
                      {tests.length > 0 ? (
                        tests.map((item, index) => (
                          <div key={index} className="mygroup__table-body--item">
                            <div className="">
                              <p>{index + 1}</p>
                            </div>
                            <div className="group-name">
                              <p>
                                <Link
                                  to={`/ad-tests/${item.id}`}
                                  onClick={() => setPage(page)}
                                >
                                  {item.test_info}
                                </Link>
                              </p>
                            </div>
                            <div>
                              <ViewStudent data={item} />
                              <TechUpdateLesson data={item} />
                              <button
                                className="btn-delete"
                                onClick={() => deleteLesson(item.id)}
                                style={{ color: "crimson" }}
                              >
                                <Delete />
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            width: "100%",
                          }}
                        >
                          {loading ? "Yuklanmoqda..." : " Ma`lumotlar mavjud emas "}
                        </div>
                      )}
                    </div>
                  </div>

                  <Stack spacing={2} alignItems="center" marginTop="20px">
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                      variant="outlined"
                      shape="rounded"
                    />
                  </Stack>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
