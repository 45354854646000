import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../lib/axios";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Footer/Footer";
// import moment from "moment";

const MyGroupsSingleAttends = () => {
  const { groupId } = useParams();
  const [attendances, setAttendances] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 10; // Number of items per page
  // const [startDate] = useState("10%2F10%2F2024");
  // const [endDate] = useState("17%2F10%2F2024");

  const config = {
    headers: {
      Authorization: localStorage.getItem("accessToken"),
    },
  };

  useEffect(() => {
    fetchAttendance();
  }, []);
  // `attendance/student-group?id=${groupId}&page=${page}&limit=${100}&start=${moment(startDate).format("DD/MM/YYYY")}&end=${moment(endDate).format("DD/MM/YYYY")}`,
  const fetchAttendance = async() => {
    try {
      await axios.get(
        `attendance/student-group?id=${groupId}&page=${page}&limit=${100}&start=${""}&end=${""}`,
        config
      ).then((response) => {
        setPage(response.data.count < limit ? 1 : Math.ceil(response.data.count / limit));
        setAttendances(response.data);
      });
    } catch (error) {
      setPage(1);
      console.error("Error fetching attendance:", error);
    }
  };

  return (
    <>
    <Header />
    <main>
    <div className="attendance-list">
      {attendances.length > 0 ? (
        attendances.map((attendance, index) => (
          <div key={index} className="attendance-item">
            <div className="attendance-date">{attendance.date.split("T")[0]}</div>
            <div className={`attendance-action action-${attendance.action}`}>
              {attendance.action === "here" ? "OK" : attendance.action === "ask" ? "+" : "-"}
            </div>
          </div>
        ))
      ) : (
        <div className="no-data">Ma`lumot mavjud emas</div>
      )}
    </div>
    </main>
    <Navbar/>
    </>
  );
};

export default MyGroupsSingleAttends;
