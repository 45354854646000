import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../Pages/NotFound/NotFound";
import Login from "../components/Auth/Login/Login.jsx";
import "../app/App.css";
import Payments from "../Pages/Payments/Payments.jsx";
import MyGroups from "../Pages/MyGroups/MyGroups.jsx";
import Rating from "./../Pages/Rating/Rating";
import Profile from "../Pages/Profile/Profile.jsx";
import ForgotPassword from "../components/Auth/ForgotPassword/ForgotPassword.jsx";
import UpdatePassword from "../components/Auth/UpdatePassword/UpdatePassword.jsx";
import Reward from "../components/Reward/Reward.jsx";
import TestResult from "../components/TestResult/TestResult.jsx";
import Attendance from "../components/Attendance/Attendance.jsx";
import Appropriation from "../components/Approptiation/Appropriation.jsx";
import TechGroup from "../Pages/teacher/TechGroups/TechGroups.jsx";
import TechLesson from "../Pages/teacher/TechLesson/TechLesson.jsx";
import TechHomework from "../Pages/teacher/TechHomework/TechHomework.jsx";
import TechReward from "../Pages/teacher/TechReward/TechReward.jsx";
import TechAttendanceRating from "../Pages/teacher/TechAttendanceRating/TechAttendanceRating.jsx";
import TechHomeworkRating from "../Pages/teacher/TechHomeworkRating/TechHomeworkRating.jsx";
// Admin
import AdStudent from "../Pages/admin/AdStudent/AdStudent.jsx";
import AdTeacher from "../Pages/admin/AdTeacher/AdTeacher.jsx";
import AdGroup from "../Pages/admin/AdGroup/AdGroup.jsx";
import AdTest from "../Pages/admin/AdTest/AdTest.jsx";
import AdPayment from "../Pages/admin/AdPayment/AdPayment.jsx";
import AdCost from "../Pages/admin/AdCost/AdCost.jsx";
import AdReward from "../Pages/admin/AdReward/AdReward.jsx";
import AdminSinglePage from "../Pages/admin/AdGroup/AdminSinglePage.jsx";
import AdminSinglePageTest from "../Pages/admin/AdTest/AdminSinglePageTest.jsx";
import AdminPaymentInner from "../Pages/admin/AdPayment/AdminPaymentInner.jsx";
import TechHomeworkInner from "../Pages/teacher/TechHomework/TechHomeworkInner.jsx";
import AdAttendance from "../Pages/admin/AdGroup/AdAttendance/AdAttendance.jsx";
import AdGroupTest from "../Pages/admin/AdGroup/AdGroupTest/AdGroupTest.jsx";
import AdminGroupTestStudent from "../Pages/admin/AdGroup/AdGroupTest/AdminGroupTestStudent.jsx";
import AdAttendanceAddStudent from "../Pages/admin/AdGroup/AdAttendance/AdAttendanceAddStudent.jsx";
import AdAttendanceDateStudent from "../Pages/admin/AdGroup/AdAttendance/AdAttendanceDateStudent.jsx";
import Protected from "../protected/protected.js";
import MyGroupsSingle from "../Pages/MyGroups/MyGroupsSingle.jsx";
import MyGroupsSingleAttends from "../Pages/MyGroups/MyGroupSingleAttends.jsx";
import MyGroupsSingleTest from "../Pages/MyGroups/MyGroupSingleTest.jsx";
import BaseTests from "../Pages/BaseTests/BaseTests.jsx";
import Statistics from "../Pages/Statistics/Statistics.jsx";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/password-update" element={<UpdatePassword />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/logout" element={<Login />} />

        <Route path="/" element={<Protected />}>
          <Route path="/" element={<Statistics />} />
          <Route path="/my-groups" element={<MyGroups />} />
          <Route path="/my-groups-single/:groupId" element={<MyGroupsSingle />} />
          <Route path="/my-groups-attends/:groupId" element={<MyGroupsSingleAttends />} />
          <Route path="/my-groups-tests/:groupId" element={<MyGroupsSingleTest />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/base-tests" element={<BaseTests />}>
            <Route index element={<Reward />} />
            <Route path="test-result" element={<TestResult />} />
          </Route>
          <Route path="/rating" element={<Rating />}>
            <Route index element={<Attendance />} />
            <Route path="appropriation" element={<Appropriation />} />
          </Route>
          <Route path="/profile" element={<Profile />} />

          {/* Teacher Routes */}
          <Route path="/tech-group" element={<TechGroup />} />
          <Route path="/tech-lesson" element={<TechLesson />} />
          <Route path="/:groupId/tech-lesson" element={<TechLesson />} />
          <Route path="/:groupId/tech-homework" element={<TechHomework />} />
          <Route
            path="/:groupId/tech-homework/:lessonId"
            element={<TechHomeworkInner />}
          />
          <Route path="/:groupId/tech-pointers" element={<TechReward />} />
          <Route
            path="/:groupId/tech-rating-attendance"
            element={<TechAttendanceRating />}
          />
          <Route
            path="/:groupId/tech-rating-homework"
            element={<TechHomeworkRating />}
          />

          {/* Admin Routes */}
          <Route path="/ad-students" element={<AdStudent />} />
          <Route path="/ad-teachers" element={<AdTeacher />} />

          <Route path="/ad-groups" element={<AdGroup />} />
          <Route path="/ad-groups-student/:id" element={<AdminSinglePage />} />
          <Route path="/ad-groups-test/:id" element={<AdGroupTest />} />
          <Route
            path="/ad-groups-test-student/:id"
            element={<AdminGroupTestStudent />}
          />
          <Route path="/ad-groups-attendance/:id" element={<AdAttendance />} />
          <Route
            path="/ad-groups-attendance-student/:id"
            element={<AdAttendanceAddStudent />}
          />
          <Route
            path="/ad-groups-attendance-date-student/:id"
            element={<AdAttendanceDateStudent />}
          />

          <Route path="/ad-tests" element={<AdTest />} />
          <Route path="/ad-tests/:id" element={<AdminSinglePageTest />} />
          <Route path="/ad-payments" element={<AdPayment />} />
          <Route
            path="/ad-payments/:studentId"
            element={<AdminPaymentInner />}
          />
          <Route path="/ad-costs" element={<AdCost />} />
          <Route path="/ad-rewards" element={<AdReward />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

/*
Siz admin sahifalarini faqatgina admin huquqiga ega foydalanuvchilarga ochib berish uchun "Protected Route" va huquqlarni tekshirish mexanizmini qo‘llashingiz mumkin. Buning uchun, foydalanuvchi ma'lumotlarini (masalan, roli yoki huquqlari) olish, so‘ngra ularning roli asosida tegishli routelarga kirish imkoniyatini cheklash kerak bo‘ladi.

Quyidagi yechimni ko‘rib chiqing:

UserContext yaratib, foydalanuvchi rolini saqlash.
PrivateRoute komponenti yaratib, huquqlarni tekshirish.
1-qadam: Foydalanuvchi holatini saqlovchi kontekst yaratish
UserContext yordamida foydalanuvchi ma'lumotlarini olish:

jsx
Copy code
// UserContext.js
import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ role: "student" }); // Boshida roli "student"

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
2-qadam: Huquqni tekshiruvchi PrivateRoute komponentini yaratish
Admin sahifalariga kirishni cheklash uchun PrivateRoute komponentini qo‘shing:

jsx
Copy code
// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "./UserContext";

const PrivateRoute = ({ children, allowedRoles }) => {
  const { user } = useUser();

  return allowedRoles.includes(user.role) ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoute;
3-qadam: Routelarni himoyalash
App.js faylingizdagi admin routelarni PrivateRoute bilan o‘rab qo‘ying va faqat admin roliga kirishga ruxsat bering:

jsx
Copy code
import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../Pages/NotFound/NotFound";
import Login from "../components/Auth/Login/Login.jsx";
import { UserProvider } from "./UserContext";
import PrivateRoute from "./PrivateRoute";
// Boshqa importlar

function App() {
  return (
    <UserProvider>
      <Routes>
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/password-update" element={<UpdatePassword />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/logout" element={<Login />} />

        <Route path="/" element={<Protected />}>
          <Route path="/" element={<MyGroups />} />

          <Route
            path="/ad-students"
            element={
              <PrivateRoute allowedRoles={["admin"]}>
                <AdStudent />
              </PrivateRoute>
            }
          />
          <Route
            path="/ad-teachers"
            element={
              <PrivateRoute allowedRoles={["admin"]}>
                <AdTeacher />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </UserProvider>
  );
}

export default App;
Shu tarzda, allowedRoles array orqali siz istalgan role uchun kirish huquqlarini cheklashingiz mumkin. Bu yerda "admin" roli bilan cheklash ishlatilgan bo‘lib, faqat admin foydalanuvchilari admin sahifalariga kira oladi.
*/
