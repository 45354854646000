import React, { useEffect, useState } from "react";
import "./TechGroups.scss";
import Header from "../../../components/Header/Header";
import axios from "../../../lib/axios";
import { NavLink, useNavigate } from "react-router-dom";
import MyModel from "../../../components/UI/MyModel/MyModel";
import TechGroupModel from "./TechGroupModel";
import TechGroupModel2 from "./TechGroupModel2";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Stack from "@mui/material/Stack"; // Import Stack

function TechGroup() {
  const useAuth = window.localStorage;
  const userId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [groups, setGroup] = useState([]);
  const [lesson, setLesson] = useState();
  const [change, setChange] = useState(false);
  const [index, setIndex] = useState();
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState();
  const [groupId, setGroupId] = useState();
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [limit] = useState(10); // Number of items per page

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    localStorage.removeItem("group_id");
    axios
      .get(`groups/tid?tid=${userId}&page=${page}&limit=${limit}`, config)
      .then((response) => {
        setGroup(response.data.groups);
        setTotalPages(response.data.count < limit ? 1 : Math.ceil(response.data.count / limit)); // Assuming your API returns the total number of pages
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]); // Re-run effect when page changes

  function handleClick(index) {
    localStorage.setItem("group_id", groups[index].id);
    setGroupId(groups[index].id);
    localStorage.setItem("group_name", groups[index].group_name);
    setIndex(index);
    setGroupName(groups[index].group_name);
  }

  useEffect(() => {
    const id = localStorage.getItem("group_id");
    id &&
      axios
        .get(`gorups/tid?tid=${id}&page=1&limit=50&tid=${userId}`, config)
        .then((response) => {
          setLesson(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    groupName && navigate(`/${groupId}/tech-lesson`);
  }, [change, index, groupName]);

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state when user changes the page
  };

  return (
    <>
      <Header />
      <main className="mainPage">
        {lesson === undefined ? (
          <section className="mygroup-section">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th className="tablet">#</th>
                      <th className="tablet">Guruh nomi</th>
                      <th>Yo`nalish</th>
                      <th>Boshlangan sana</th>
                      <th>Tugagan sana</th>
                      <th>Dars vaqti</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups &&
                      groups.map((item, index) => (
                        <tr key={index} id="margin">
                          <td className="tablet">{index + 1}</td>
                          <td className="tablet">
                            <NavLink onClick={() => handleClick(index)}>
                              {item.group_name}
                            </NavLink>
                          </td>
                          <td>{item.tag_name}</td>
                          <td>
                            {item.start_date.split("T")[0]}
                          </td>
                          <td>{item.end_date !== "" ? item.end_date.split("T")[0] : "Noma'lum"}</td>
                          <td>
                            {item.start_time.split("T")[1].slice(0, -4)}-
                            {item.end_time === "" ? "20:00" : item.end_time.split("T")[1].slice(0, -4)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Guruh nomi</div>
                    <div></div>
                  </div>
                  <div className="mygroup__table-body">
                    {groups.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item">
                        <div className=""><p>{index + 1}</p></div>
                        <div className="group-name">
                         <p> <NavLink onClick={() => handleClick(index)}>
                            {item.group_name}
                          </NavLink></p>
                        </div>
                        <div className="group-info">
                          <TechGroupModel data={item} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Pagination Component */}
                <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </div>
          </section>
        ) : (
          <section className="mygroup-section">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Dars nomi</th>
                      <th>Dars sana</th>
                      <th>Vazifa</th>
                      <th>Ball</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lesson &&
                      lesson.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <p onClick={() => setChange(!change)}>
                              {item.lesson_name}
                            </p>
                          </td>
                          <td>{item.lesson_date.split("T")[0]}</td>
                          <td>
                            <MyModel data={item.description} />
                          </td>
                          <td>
                            {item.description === "undefined" ? "-" : item.point}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile tech-group">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Dars nomi</div>
                  </div>
                  <div className="mygroup__table-body">
                    {lesson &&
                      lesson.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div className="">{index + 1}</div>
                          <div className="group-name">{item.lesson_name}</div>
                          <div>
                            <TechGroupModel2 data={item} />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default TechGroup;
