import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { MoreTime } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
// import moment from "moment";
// import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminPaymentGenerate() {
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    console.log(month, year);
  }, []);

  const updateStudent = (e) => {
    e.preventDefault();
    const formData = {
      month: month - 0,
      year: year - 0,
    };
    axios
      .post("/payment", formData, config)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(formData);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreTime />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
              {/* <TextField
                required
                label="O'zgartiriladigan to'lov miqdori"
                style={{ width: "100%" }}
                type="text"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(Number(e.target.value))}
              /> */}
              {/* <TextField
                required
                style={{ width: "100%" }}
                value={pay}
                type="text"
                onChange={(e) => setPay(e.target.value)}
              /> */}
              <TextField
                required
                style={{ width: "100%" }}
                value={year}
                type="text"
                onChange={(e) => setYear(e.target.value)}
              />
              <TextField
                required
                style={{ width: "100%" }}
                value={month}
                type="text"
                onChange={(e) => setMonth(e.target.value)}
              />
              {/* <TextField
                required
                style={{ width: "100%" }}
                value={paymentType}
                onChange={(e) => {
                  setPaymentType(e.target.value);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>To`lov turini tanlang</option>
                <option value="cash">Naqd</option>
                <option value="card">Plastik</option>
              </TextField> */}
              <Button variant="contained" style={{ width: "100%" }} onClick={updateStudent}>
                Generatasiya qilish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
