import React, { useEffect, useState } from "react";
import axios from "../../../lib/axios";
import "./AdPayment.scss";

// import TechAddLesson from "./AdminAddPayment";
import { useNavigate } from "react-router-dom";
import AdNavbar from "../AdNavbar/AdNavbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import AdminUpdatePayment from "./AdminUpdatePayment";
import AdminPaymentGenerate from "./AdminPaymentGenerate";
import CircularProgress from "@mui/material/CircularProgress"; // Yuklanish belgisi uchun
import AdminAddUserPayment from "./AdminAddUserPayment";

export default function AdPayment() {
  const useAuth = window.localStorage;
  const navigate = useNavigate();
  const Token = useAuth.getItem("accessToken");
  const [students, setStudents] = useState(null); // Dastlab null qiymat
  const [loading, setLoading] = useState(true); // Yuklanish holati
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const [year, setYear] = useState(localStorage.getItem("year"));
  const [month, setMonth] = useState(localStorage.getItem("month"));
  const [need, setNeed] = useState(0);
  const [pay, setPay] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [status, setStatus] = useState(localStorage.getItem("status") ? localStorage.getItem("status") : "false");

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    localStorage.setItem("month", month);
    localStorage.setItem("year", year);
    localStorage.setItem("status", status);
    setLoading(true); // Ma`lumotlar yuklanayotganini ko'rsatish
    axios
      .get(
        `/payment?page=${page}&limit=${limit}&year=${year}&month=${month}&status=${status}`,
        config,
      )
      .then((response) => {
        setStudents(response.data.payments);
        setTotalPages(
          response.data.count < limit ? 1 : Math.ceil(response.data.count / limit),
        );
        setLoading(false); // Ma`lumotlar yuklangach yuklanish belgisi o'chiriladi
      })
      .catch((err) => {
        if (err.response.status === 401) navigate("/logout");
        setLoading(false); // Xatolik yuz berganda ham yuklanish belgisi o'chiriladi
      });
  }, [page, year, month, status]);

  useEffect(() => {
    axios
      .get(`/payment/total?year=${year}&month=${month}`, config)
      .then((response) => {
        setNeed(response.data.need);
        setPay(response.data.pay);
        setDiscount(response.data.discount);
      })
      .catch((err) => {
        if (err.response.status === 401) navigate("/logout");
      });
  }, [year, month]);

  const handlePageChange = (event, value) => setPage(value);

  return (
    <>
      <AdNavbar />
      <main className="mainPage">
        <section className="mygroup-section tech-lesson-section adpayment">
          <div className="container">
            <div className="total_payment">
              <div className="select_wrapper">
                <select value={year} onChange={(e) => setYear(e.target.value)}>
                  <option value="2027">2027</option>
                  <option value="2026">2026</option>
                  <option value="2025">2025</option>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                </select>
                <select
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="1">Yanvar</option>
                  <option value="2">Fevral</option>
                  <option value="3">Mart</option>
                  <option value="4">Aprel</option>
                  <option value="5">May</option>
                  <option value="6">Iyun</option>
                  <option value="7">Iyul</option>
                  <option value="8">August</option>
                  <option value="9">Sentabr</option>
                  <option value="10">Oktabr</option>
                  <option value="11">Noyabr</option>
                  <option value="12">Dekabr</option>
                </select>
                <select value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="false">Qarzdor</option>
                  <option value="true">To`langan</option>
                </select>
                <div className="total_payment_info">
                  <p>To`lanishi kerak: {need}</p>
                  <p>To`langan: {pay}</p>
                  <p>Chegirma: {discount}</p>
                </div>
                <AdminPaymentGenerate />
              </div>
            </div>
            <div className="mygroup">
              {loading ? (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <CircularProgress />
                </div>
              ) : (
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Talabalar</th>
                      <th>Telefon raqam</th>
                      <th>To`lov</th>
                      <th className="add-wrapper">
                        Action
                        <AdminAddUserPayment />
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students && students.length > 0 ? (
                      students.map((item, index) => (
                        <tr key={index} id={item.id}>
                          <td>{index + 1}</td>
                          <td id="lessonName">
                            <p
                              style={
                                item.status ? { color: "green" } : { color: "red" }
                              }
                            >
                              {`${item.first_name} ${item.last_name}`}
                            </p>
                          </td>
                          <td>+{item.phone_number}</td>
                          <td>
                            <p style={{ color: "red" }}>{item.need}</p>
                            <p style={{ color: "green" }}>{item.pay}</p>
                            <p style={{ color: "royalblue" }}>{item.discount}</p>
                          </td>
                          <td className="">
                            <AdminUpdatePayment data={[item, year, month]} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          Ma`lumot mavjud emas
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}

              <Stack spacing={2} alignItems="center" marginTop="20px">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="secondary"
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
