import React, { useEffect, useState } from "react";
import axios from "../../../lib/axios";
import "./AdStudent.scss";

import { Delete } from "@mui/icons-material";
import TechAddLesson from "./AdminAddStudent";
import TechUpdateLesson from "./AdminUpdateStudent";
import { useNavigate } from "react-router-dom";
import AdNavbar from "../AdNavbar/AdNavbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function AdStudent() {
  const useAuth = window.localStorage;
  const navigate = useNavigate();
  const Token = useAuth.getItem("accessToken");
  const [students, setStudents] = useState([]);
  const [change, setChange] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get("users?page=" + page + "&limit=" + limit, config)
      .then((response) => {
        setStudents(response.data.users || []);
        setTotalPages(response.data.count < limit ? 1 : Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        if (err.response === 401) {
          navigate("/logout");
        }
        console.log(err);
      });
  }, [page]);

  const deleteLesson = (id) => {
    if (confirm("O`chirmoqchimisiz?")) {
      axios
        .delete(`user/${id}`, config)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleAction = (e) => {
    const actionBody = e.target.nextSibling;
    if (actionBody.style.display === "block") {
      actionBody.style.display = "none";
    } else {
      actionBody.style.display = "block";
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <AdNavbar />
      <main className="mainPage">
        <section className="mygroup-section tech-lesson-section ad-student">
          <div className="container">
            <div className="mygroup">
              <table className="mygroup__table desktop">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Talaba</th>
                    <th>Telefon raqam</th>
                    <th>Yaratilgan sana</th>
                    <th className="add-wrapper">
                      <TechAddLesson />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {students.length > 0 ? (
                    students.map((item, index) => (
                      <tr key={index} id={item.id}>
                        <td>{index + 1}</td>
                        <td id="lessonName">
                          <p onClick={() => setChange(!change)}>
                            {item.first_name + " " + item.last_name}
                          </p>
                        </td>
                        <td>
                          <p>+{item.phone_number}</p>
                        </td>
                        <td>{item.created_at}</td>
                        <td className="add-wrapper">
                          <TechUpdateLesson data={item} />
                          <button
                            className="btn-delete"
                            onClick={() => deleteLesson(item.user_id)}
                            style={{ color: "crimson" }}
                          >
                            <Delete />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        Ma`lumotlar mavjud emas
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="mygroup__table mobile">
                <div className="mygroup__table-head">
                  <div className="">#</div>
                  <div>Talabar</div>
                  <div className="login">Telefon raqam</div>
                  <TechAddLesson />
                </div>
                <div className="mygroup__table-body">
                  {students.length > 0 ? (
                    students.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item">
                        <div className=""><p>{index + 1}</p></div>
                        <div className="group-name">
                          <p onClick={() => setChange(!change)}>
                            {item.first_name + " " + item.last_name}
                          </p>
                        </div>
                        <div><p>+{item.phone_number}</p></div>
                        <div>
                          <button className="btn-action" onClick={toggleAction}>
                            •••
                          </button>
                          <div className="adstudent-action-body">
                            <TechUpdateLesson data={item} />
                            <button
                              className="btn-delete"
                              onClick={() => deleteLesson(item.user_id)}
                              style={{ color: "crimson" }}
                            >
                              <Delete />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: "center", padding: "20px", width: "100%" }}>
                      Ma`lumotlar mavjud emas
                    </div>
                  )}
                </div>
              </div>
              <Stack spacing={2} alignItems="center" marginTop="20px">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="secondary"
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
