import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
// import axios from "../../../../lib/axios";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import axios from "../../../lib/axios";
// import moment from "moment";
// import { max } from "moment";
// import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdTestStudentUpdate(data) {
  const updateData = data.data;
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [disabled, setDisabled] = useState(true);
  // const [name, setName] = useState(updateData.name);
  const [studentScore, setStudentScore] = useState(updateData.student_point);
  // const [date, setDate] = useState(updateData.date);
  const { id } = useParams();
  // const [studentId] = useState(updateData.student_id);
  // const [studentScore, setStudentScore] = useState(updateData.student_point);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  // Ma`lumotlarni to'ldirish
  // useEffect(() => {
  //   if (updateData) {
  //     console.log(updateData);
  //     setTestDate(moment(updateData.test_date).format("YYYY-MM-DD") || "");
  //     setTestInfo(updateData.name || "");
  //     setMaxScore(updateData.max_point || "");
  //   }
  // }, [updateData]);

  // Barcha inputlar to'ldirilganda disabled o'zgaruvchisini boshqarish
  useEffect(() => {
    if (studentScore) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [studentScore]);

  const updateTest = async(e) => {
    e.preventDefault();
    console.log(updateData);
    const formData = {
      base_test_id: id,
      student_id: updateData.student_id,
      student_point: studentScore - 0
      // "date": "string",
      // "group_id": "string",
      // "group_test_id": "string",
      // "max_point": 0,
      // "name": "string"
    };

    try {
      await axios.put("/base-test/reset-student-point", formData, config);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              {/* <TextField
                required
                label="Test nomi"
                style={{ width: "100%" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              /> */}
              <TextField
                required
                label="Ball"
                focused
                style={{ width: "100%" }}
                type="number"
                value={studentScore}
                onChange={(e) => setStudentScore(e.target.value)}
              />
              {/* <TextField
                required
                label="Vaqti"
                focused
                style={{ width: "100%" }}
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              /> */}
              {/* <TextField
                label="Max ball"
                style={{ width: "100%" }}
                value={maxScore}
                type="text"
                onChange={(e) => setMaxScore(e.target.value)}
              /> */}
              {/* <TextField
                required
                label="Vaqti"
                focused
                style={{ width: "100%" }}
                type="date"
                value={testDate}
                onChange={(e) => setTestDate(e.target.value)}
              /> */}
              <Button
                variant="contained"
                style={{ width: "100%" }}
                // type="submit"
                onClick={updateTest}
                disabled={disabled}
              >
                Yangilash
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
