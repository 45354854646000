import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // To'g'ri import

function Protected() {
  const accessToken = localStorage.getItem("accessToken"); // Tokenni olish

  if (accessToken) {
    try {
      const accessDecode = jwtDecode(accessToken); // Tokenni dekodlash

      // Agar token muddati tugamagan bo'lsa, Outlet-ni ko'rsatadi
      if (accessDecode.exp > Date.now() / 1000) {
        return <Outlet />;
      } else {
        // Token muddati tugagan bo'lsa, logout sahifasiga yo'naltiradi
        return <Navigate to="/logout" />;
      }
    } catch (error) {
      // Agar xatolik bo'lsa (masalan, noto'g'ri token formatida)
      return <Navigate to="/logout" />;
    }
  } else {
    // Token mavjud bo'lmasa, logout sahifasiga yo'naltiradi
    return <Navigate to="/logout" />;
  }
}

export default Protected;
