import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../lib/axios";
import "./AdminSinglePage.scss";
import AdminAddStudentToGroup from "./AdminAddStudentToGroup";
import { Delete } from "@mui/icons-material";
import AdGroupNavbar from "./AdGroupNavbar/AdGroupNavbar";

export default function AdminSinglePage() {
  const useAuth = window.localStorage;
  const { id: groupID } = useParams();
  const Token = useAuth.getItem("accessToken");
  const [students, setStudents] = useState([]);
  const [groupId, setGroupId] = useState(groupID);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get("group/group-students/" + groupID, config)
      .then((response) => {
        setStudents(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [groupID]);

  const deleteLesson = (studentId) => {
    console.log(studentId, groupId);
    if (confirm("O'chirishni istaysizmi?")) {
      setGroupId(groupID);
      axios
        .delete(`group/remove-student?gid=${groupId}&sid=${studentId}`, config)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <AdGroupNavbar/>
      <section className="admin-single-group-section">
        <div className="container">
          {/* <div>
            <Link>Talabalar</Link>
            <Link to={"/ad-attendance/" + groupId}>Test</Link>
            <Link>Davomat</Link>
            </div> */}
          <div className="admin-single">
            <section className="mygroup-section tech-lesson-section admin-group-section">

                <div className="mygroup">
                  {<>
                      <table className="mygroup__table desktop">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Talabalar</th>
                            <th>Telefon raqam</th>
                            <th className="add-wrapper">
                              <AdminAddStudentToGroup />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {students && students.map((item, index) => (
                            <tr key={index} id={item.id}>
                              <td>{index + 1}</td>
                              <td id="lessonName">
                                {item.first_name} {item.last_name}
                              </td>
                              <td>+{item.phone_number}</td>
                              <td className="add-wrapper">
                                <button
                                  className="btn-delete"
                                  onClick={() => deleteLesson(item.user_id)}
                                  style={{ color: "crimson" }}
                                >
                                  <Delete />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="mygroup__table mobile">
                        <div className="mygroup__table-head">
                          <div className="">#</div>
                          <div className="">Talabalar</div>
                          <AdminAddStudentToGroup />
                        </div>
                        <div className="mygroup__table-body">
                          {students && students.map((item, index) => (
                            <div key={index} className="mygroup__table-body--item">
                              <div style={{ padding: "16px" }} className="">
                                <p>{index + 1}</p>
                              </div>
                              <div style={{ padding: "16px" }} className="group-name">
                                <p>{item.first_name} {item.last_name}</p>
                              </div>
                              <div>
                                <button
                                  className="btn-delete"
                                  onClick={() => deleteLesson(item.id)}
                                  style={{ color: "crimson" }}
                                >
                                  <Delete />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>}
                    {students == null && <p style={{ textAlign: "center", color: "red", marginTop: "20px" }}>Ma`lumotlar mavjud emas</p>}
                </div>

            </section>
          </div>
        </div>
      </section>
    </>
  );
}
