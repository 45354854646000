import React, { useState } from "react";
import "./AdGroupNavbar.scss";
// import logo from "../../../assets/logo/logo.png";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";
import axios from "../../../../lib/axios";
// import axios from "../../../lib/axios";
import logo from "../../../../assets/logo/logo.png";
import { KeyboardReturn } from "@mui/icons-material";
import Button from "@mui/material/Button";

function AdGroupNavbar() {
  const [menu, setMenu] = useState(false);
  // const [check, setCheck] = useState(false);
  const [menuBar, setMenuBar] = useState();
  const navigate = useNavigate();

  const REFRESH_TOKEN = localStorage.getItem("refreshToken");

  const menuHandler = (e) => {
    setMenu((a) => !a);
    setMenuBar(document.querySelector(".logout"));
    console.dir(menuBar);
  };

  const useAuth = window.localStorage;
  const accessToken = useAuth.getItem("accessToken");
  const groupId = useAuth.getItem("groupId");

  if (accessToken !== null) {
    const accessDecode = jwtDecode(accessToken);
    const refreshDecode = jwtDecode(REFRESH_TOKEN);
    if (accessDecode.exp > Date.now() / 1000 || !accessDecode) {
      console.log("Token hali yangi");
    } else if (refreshDecode.exp > Date.now() / 1000) {
      axios
        .get(`token/${REFRESH_TOKEN}`)
        .then((response) => {
          localStorage.setItem("accessToken", response.data.access_token);
          // setCheck(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.reload();
          }
          navigate("/logout");
          console.log(err);
        });
      // setCheck(true);
    } else {
      window.location.reload();
      return <Navigate to={"/logout"} />;
    }
  } else {
    return <Navigate to={"/logout"} />;
  }

  return (
    <>
      <header className="header-section admin-header-section">
        <div className="container">
          <nav className="header__nav">
            {/* <div className="logo__wrapper">
              <h2>
                <NavLink
                  className={"logo"}
                  title="Return to the main page"
                  to={"/tech-group"}
                >
                  {groupName}
                </NavLink>
              </h2>
            </div> */}

            <div className="logo__wrapper">
              <NavLink className={"logo__link"}>
                <img className="header__logo" src={logo} alt="Site logo" />
              </NavLink>
            </div>

            <ul className="desktop-menu">
              <li>
                <NavLink to={"/ad-groups-student/" + groupId}>
                  Talabalar
                </NavLink>
              </li>
              <li>
                <NavLink to={"/ad-groups-test/" + groupId}>Testlar</NavLink>
              </li>
              <li>
                <NavLink to={"/ad-groups-attendance/" + groupId}>
                  Davomat
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/ad-costs">Xarajatlar</NavLink>
              </li>
              <li>
                <NavLink to="/ad-rewards">Mukofotlar</NavLink>
              </li> */}
              <li className="d-none menu-bar" onClick={menuHandler}>
                <IconButton edge="start" color="inherit" aria-label="menu">
                  <MenuIcon />
                </IconButton>
              </li>
            </ul>
            {menu && (
              <>
                <ul className="mobile-menu">
                  <li>
                    <NavLink to={"/ad-groups-student/" + groupId}>
                      Talabalar
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/ad-groups-test/" + groupId}>Testlar</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/ad-groups-attendance/" + groupId}>
                      Davomat
                    </NavLink>
                    <NavLink className={"block"} to="/logout">
                      <FontAwesomeIcon
                        icon={faSignOut}
                        size="lg"
                        color="green"
                      />
                    </NavLink>
                  </li>
                  {/* <li style={{ justifyContent: "flex-end" }}>
                  <NavLink to="/ad-costs">Xarajatlar</NavLink>
                </li> */}
                  {/* <li>
                  <NavLink to="/ad-rewards">Mukofotlar</NavLink>
                    <NavLink className={"block"} to="/logout">
                      <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
                    </NavLink>
                  </li> */}
                </ul>
              </>
            )}
            {/* <NavLink className={"logout"} to="/logout">
              <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
            </NavLink> */}
            <NavLink to="/ad-groups" className="">
              <Button>
                <KeyboardReturn />
              </Button>
            </NavLink>
          </nav>
        </div>
      </header>
    </>
  );
}

export default AdGroupNavbar;
