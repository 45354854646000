import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminAddGroup() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [disabled, setDisabled] = useState(true);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const createGroup = (e) => {
    e.preventDefault();
    const formData = {
      name,
      price: parseFloat(price),
    };

    axios
      .post("/group", formData, config)
      .then((response) => {
        console.log(response.data);
        setDisabled(false);
        window.location.reload(); // Sahifani yangilash
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (name && price > 0 && !isNaN(price)) {
      setDisabled(false); // Ma`lumotlar to'g'ri kiritilganda tugma aktiv bo'ladi
    } else {
      setDisabled(true); // Agar Ma`lumotlar noto'g'ri bo'lsa, tugma deaktiv bo'ladi
    }
  }, [name, price]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
              <TextField
                required
                label="Guruh nomi"
                style={{ width: "100%" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!name} // Nomi bo'sh bo'lsa xato beradi
                helperText={!name ? "Guruh nomini kiriting" : ""}
              />
              <TextField
                required
                label="Narx"
                type="number"
                style={{ width: "100%" }}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                error={price <= 0 || isNaN(price)} // Narx noto'g'ri bo'lsa xato beradi
                helperText={price <= 0 || isNaN(price) ? "Narx to'g'ri bo'lishi kerak" : ""}
              />
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={createGroup}
                disabled={disabled} // Tugma faqat to'g'ri Ma`lumotlar kiritilganda aktiv bo'ladi
              >
                Guruh yaratish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
