import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function TechUpdateLesson(item) {
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [firstName, setFirstName] = useState(item.data.first_name);
  const [lastName, setLastName] = useState(item.data.last_name);
  const [phoneNumber, setPhoneNumber] = useState(item.data.phone_number.slice(3));
  const [disabled, setDisabled] = useState(true); // Tugmani boshqarish uchun holat
  const { width } = useWindowDimensions();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const updateStudent = (e) => {
    e.preventDefault();
    const formData = {
      first_name: firstName,
      last_name: lastName,
      phone_number: "998" + phoneNumber,
      user_id: item.data.user_id,
    };

    axios
      .put("/user", formData, config)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // Ma`lumotlar to'g'ri kiritilganini tekshirish
    if (firstName && lastName && phoneNumber.length === 9) {
      setDisabled(false); // Ma`lumotlar to'g'ri bo'lsa, tugma aktiv bo'ladi
    } else {
      setDisabled(true); // Aks holda tugma deaktiv bo'ladi
    }
  }, [firstName, lastName, phoneNumber]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={width < 500 ? { display: "flex", flexDirection: "column", rowGap: "20px" } : { display: "flex", flexDirection: "column", rowGap: "20px" }}
            >
              <TextField
                required
                label="Ism"
                style={width < 500 ? { width: "100%" } : { width: "100%" }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={!firstName} // Ism kiritilmagan bo'lsa xato holati
                helperText={!firstName ? "Ismni kiriting" : ""}
              />
              <TextField
                required
                label="Familiya"
                style={width < 500 ? { width: "100%" } : { width: "100%" }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={!lastName} // Familiya kiritilmagan bo'lsa xato holati
                helperText={!lastName ? "Familiyani kiriting" : ""}
              />
              <TextField
                required
                label="Telefon raqami"
                style={width < 500 ? { width: "100%" } : { width: "100%" }}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={phoneNumber.length !== 9 || isNaN(phoneNumber)} // Telefon raqam noto'g'ri bo'lsa xato holati
                helperText={ phoneNumber.length !== 9 || isNaN(phoneNumber) ? "Telefon raqami 9 raqamdan iborat bo'lishi kerak" : ""
                }
              />
              {width < 500 ? (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="contained"
                    style={{ width: "100%" }}
                    onClick={updateStudent}
                    disabled={disabled} // Tugmani holatini boshqarish
                  >
                    Yangilash
                  </Button>
                  <Button variant="outlined" style={{ width: "100%" }} onClick={handleClose}>
                    Ortga
                  </Button>
                </div>
              ) : (
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={updateStudent}
                  disabled={disabled} // Tugma faqatgina Ma`lumotlar to'g'ri bo'lsa aktiv bo'ladi
                >
                  Yangilash
                </Button>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
