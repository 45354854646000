import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Footer/Footer";
import axios from "../../lib/axios";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const MyGroupsSingleTest = () => {
  const { groupId } = useParams();
  const [tests, setTests] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 10; // Number of items per page
  const [totalPages, setTotalPages] = useState(1);
  const studentId = localStorage.getItem("guid");

  const config = {
    headers: {
      Authorization: localStorage.getItem("accessToken"),
    },
  };

  useEffect(() => {
    fetchTests();
  }, [page]);

  const fetchTests = async() => {
    try {
      const response = await axios.get(
        `group-test/student-result?sid=${studentId}&gid=${groupId}&page=${page}&limit=${limit}`,
        config
      );
      setTotalPages(response.data.count < limit ? 1 : Math.ceil(response.data.count / limit));
      // setTotalPages(Math.ceil(response.data.count / limit));
      setTests(response.data.tests);
    } catch (error) {
      // setPage(1);
      console.error("Error fetching tests:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
    <Header/>
    <main>
    <div className="tests-list">
      {tests && tests.length > 0 ? (
      <>
        {tests.map((test, index) => (
          <div key={index} className="test-item">
            <div className="test-name">{test.name}</div>
            <div className="test-details">
              <p className="test-max-point">Maksimal ballsdf: {test.max_point}</p>
              <p className="test-self-point">Sizning ball: {test.self_point}</p>
              <p className="test-rating">Reyting: {test.rating}</p>
              <p className="test-date">{new Date(test.date).toLocaleDateString()}</p>
            </div>
          </div>
        ))}
      </>
      ) : (
        <>
          <div className="no-data">Ma`lumot mavjud emas</div>
        </>
      )}
        <Stack spacing={2} alignItems="center" marginTop="20px">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="secondary"
                variant="outlined"
                shape="rounded"
              />
          </Stack>
    </div>
    </main>
    <Navbar/>
    </>
  );
};

export default MyGroupsSingleTest;
