import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
// import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminAddUserPayment() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [month] = useState(Number(localStorage.getItem("month")));
  // const [paymentDate, setPaymentDate] = useState("");
  const [pay, setPay] = useState(0);
  const [studentId, setStudentId] = useState("");
  const [students, setStudents] = useState([]);
  // const [groups, setGroups] = useState([]);
  const [need, setNeed] = useState(0);
  const [year] = useState(Number(localStorage.getItem("year")));

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get("/user/select", config)
      .then((response) => {
        setStudents(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Guruhlar Ma`lumotini olish uchun (hozircha to'ldirilmagan)
    // setGroups();
  }, []);

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      discount,
      need,
      pay,
      month,
      year,
      student_id: studentId,
    };
    // {
    //   "discount": 0,
    //   "month": 0,
    //   "need": 0,
    //   "pay": 0,
    //   "student_id": "string",
    //   "year": 0
    // }
    axios
      .post("/payment/add-student", formData, config)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (studentId && need) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [discount, month, need, pay, studentId, year]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add style={{ color: "white" }}/>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              onSubmit={createStudent}
              style={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
              <TextField
                required
                label="Chegirma"
                type="text"
                style={{ width: "100%" }}
                value={discount}
                onChange={(e) => setDiscount(Number(e.target.value) ? Number(e.target.value) : "")}
                error={discount < 0}
                helperText={discount < 0 ? "0 dan katta son kiriting" : ""}
              />
              <TextField
                required
                label="To'lanishi kerak"
                type="text"
                style={{ width: "100%" }}
                value={need}
                onChange={(e) => setNeed(Number(e.target.value) ? Number(e.target.value) : "")}
                error={need < 0}
                helperText={need < 0 ? "0 dan katta son kiriting" : ""}
              />
              <TextField
                required
                label="To'langan"
                type="text"
                style={{ width: "100%" }}
                value={pay}
                onChange={(e) => setPay(Number(e.target.value) ? Number(e.target.value) : "")}
                error={pay < 0}
                helperText={pay < 0 ? "0 dan katta son kiriting" : ""}
              />
              {/* <TextField
                required
                type="date"
                style={{ width: "100%" }}
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                autoFocus
              /> */}
              {/* <TextField
                required
                style={{ width: "100%" }}
                value={pay}
                onChange={(e) => {
                  setPay(e.target.value);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>To`lov turini tanlang</option>
                <option value="cash">Naqd</option>
                <option value="card">Plastik</option>
              </TextField> */}
              {/* <TextField
                required
                style={{ width: "100%" }}
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>Guruhni tanlang</option>
                {groups &&
                  groups.map((item) => (
                    <option key={item.group_id} value={item.group_id}>
                      {item.group_name}
                    </option>
                  ))}
              </TextField> */}
              <TextField
                required
                style={{ width: "100%" }}
                value={studentId}
                onChange={(e) => setStudentId(e.target.value)}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>Stundent tanlang</option>
                {students &&
                  students.map((item) => (
                    <option key={item.student_id} value={item.student_id}>
                      {item.name}
                    </option>
                  ))}
              </TextField>
              <Button variant="contained" style={{ width: "100%" }} onClick={createStudent} disabled={disabled}>
                Yaratish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
