import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminUpdatePayment(item) {
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [id] = useState(item.data[0].student_id);
  const [discount, setDiscount] = useState(item.data[0].discount);
  const [need, setNeed] = useState(item.data[0].need);
  const [pay, setPay] = useState(item.data[0].pay);
  const [isValid, setIsValid] = useState(false); // Validatsiya uchun o'zgartirish

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    // Validatsiyani tekshirish
    if (discount >= 0 && need >= 0 && pay >= 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [discount, need, pay]);

  const updateStudent = (e) => {
    e.preventDefault();
    if (!isValid) {
      alert("Iltimos, Ma`lumotlarni to'ldiring.");
      return;
    }

    const formData = {
      student_id: id,
      discount: discount - 0, // Raqamni matndan ajratish uchun
      need: need - 0,
      pay: pay - 0,
      year: item.data[1] - 0,
      month: item.data[2] - 0,
    };

    axios
      .put("/payment", formData, config)
      .then((data) => {
        window.location.reload();
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
              <TextField
                required
                label="To'langan"
                style={{ width: "100%" }}
                value={pay}
                type="number"
                onChange={(e) => setPay(e.target.value)}
              />
              <TextField
                required
                label="To'lanishi kerak"
                style={{ width: "100%" }}
                value={need}
                type="number"
                onChange={(e) => setNeed(e.target.value)}
              />
              <TextField
                required
                label="Chegirma"
                style={{ width: "100%" }}
                value={discount}
                type="number"
                onChange={(e) => setDiscount(e.target.value)}
              />
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={updateStudent}
                disabled={!isValid} // Yuborishdan oldin validatsiyani tekshirish
              >
                Yangilash
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
