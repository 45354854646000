import React from "react";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faChartBar, faMoneyBill, faCog, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function Navbar() {
  console.log(window.location);
  return (
    <nav className="navbar">
      <ul>
      <li><NavLink className={({ isActive, isPending }) => isActive || window.location.pathname === "/" ? "active" : ""
            } to={"/"}><FontAwesomeIcon icon={faHome} /></NavLink></li><li><NavLink className={({ isActive, isPending }) => isActive || window.location.pathname.includes("/my-groups") ? "active" : ""
          } to={"/my-groups"}><FontAwesomeIcon icon={faPeopleGroup} /></NavLink></li>
        <li><NavLink className={({ isActive, isPending }) => isActive || window.location.pathname.includes("/base-tests") ? "active" : ""
            } to={"/base-tests"}><FontAwesomeIcon icon={faChartBar} /></NavLink></li>
        <li><NavLink className={({ isActive, isPending }) => isActive || window.location.pathname.includes("/rating") ? "active" : ""
            } to={"/payments"}><FontAwesomeIcon icon={faMoneyBill} /></NavLink></li>
        <li><NavLink className={({ isActive, isPending }) => isActive || window.location.pathname.includes("/profile") ? "active" : ""
            } to={"/profile"}><FontAwesomeIcon icon={faCog} /></NavLink></li>
      </ul>
    </nav>
  );
}

export default Navbar;
