import axios from "../lib/axios";
import { useState } from "react";

export const useLogin = () => {
  const [auth, setAuth] = useState({});
  const [error, setError] = useState({});

  const connectApi = (email, password) => {
    axios
      .post("/auth/login", { phone_number: email, password })
      .then((result) => setAuth(result.data))
      .catch((err) => {
        setError(err);
      });
  };
  return [auth, connectApi, error];
};
