import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Footer/Footer";
import axios from "../../lib/axios";
import "./BaseTests.scss";

const BaseTests = () => {
  const [tests, setTests] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 10; // Number of items per page
  const studentId = localStorage.getItem("guid");

  const config = {
    headers: {
      Authorization: localStorage.getItem("accessToken"),
    },
  };

  useEffect(() => {
    fetchTests();
  }, [page]);

  const fetchTests = async() => {
    try {
      const response = await axios.get(
        `base-test/student-result?id=${studentId}&page=${page}&limit=${limit}`,
        config
      );
      // base-test/student-result?id=32c1ab17-ecce-424e-98c9-f22ef3b134ae&page=1&limit=1
      setPage(response.data.count < limit ? 1 : Math.ceil(response.data.count / limit));
      setTests(response.data.tests);
    } catch (error) {
      setPage(1);
      console.error("Error fetching tests:", error);
    }
  };

  return (
    <>
    <Header/>
    <main>
    <div className="tests-list">
      {tests && tests.length > 0 ? (
        tests.map((test, index) => (
          <div key={index} className="test-item">
            <div className="test-name">{test.name}</div>
            <div className="test-details">
              <p className="test-max-point">Maksimal ball: {test.max_point}</p>
              <p className="test-self-point">Sizning ball: {test.self_point}</p>
              <p className="test-rating">Reyting: {test.rating}</p>
              <p className="test-date">{new Date(test.date).toLocaleDateString()}</p>
            </div>
          </div>
        ))
      ) : (
        <div className="no-data">Ma`lumot mavjud emas</div>
      )}
    </div>
    </main>
    <Navbar/>
    </>
  );
};

export default BaseTests;
