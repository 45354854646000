import React, { useState } from "react";
import "./AdNavbar.scss";
// import logo from "../../../assets/logo/logo.png";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/logo/logo.png";

function AdNavbar() {
  const [menu, setMenu] = useState(false);
  // const [check, setCheck] = useState(false);
  const [menuBar, setMenuBar] = useState();

  const menuHandler = (e) => {
    setMenu((a) => !a);
    setMenuBar(document.querySelector(".logout"));
    console.dir(menuBar);
  };

  return (
    <>
      <header className="header-section admin-header-section">
        <div className="container">
          <nav className="header__nav">
            {/* <div className="logo__wrapper">
              <h2>
                <NavLink
                  className={"logo"}
                  title="Return to the main page"
                  to={"/tech-group"}
                >
                  {groupName}
                </NavLink>
              </h2>
            </div> */}

            <div className="logo__wrapper">
              <NavLink className={"logo__link"}>
                <img className="header__logo" src={logo} alt="Site logo" />
              </NavLink>
            </div>

            <ul className="desktop-menu">
              <li>
                <NavLink to="/ad-students">Talabalar</NavLink>
              </li>
              {/* <li>
                <NavLink to="/ad-teachers">Ustozlar</NavLink>
              </li> */}
              <li>
                <NavLink to="/ad-groups">Guruhlar</NavLink>
              </li>
              <li>
                <NavLink to="/ad-tests">Testlar</NavLink>
              </li>
              <li>
                <NavLink to="/ad-payments">To`lovlar</NavLink>
              </li>
              {/* <li>
                <NavLink to="/ad-costs">Xarajatlar</NavLink>
              </li>
              <li>
                <NavLink to="/ad-rewards">Mukofotlar</NavLink>
              </li> */}
              <li className="d-none menu-bar" onClick={menuHandler}>
                <IconButton edge="start" color="inherit" aria-label="menu">
                  <MenuIcon />
                </IconButton>
              </li>
            </ul>
            {menu && (
              <>
              <ul className="mobile-menu">
                <li>
                <NavLink to="/ad-students">Talabalar</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/ad-teachers">Ustozlar</NavLink>
                </li> */}
                <li>
                  <NavLink to="/ad-groups">Guruhlar</NavLink>
                </li>
                <li>
                  <NavLink to="/ad-tests">Testlar</NavLink>
                </li>
                <li>
                  <NavLink to="/ad-payments">To`lovlar</NavLink>
                  <NavLink className={"block"} to="/logout">
                      <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
                    </NavLink>
                </li>
                {/* <li style={{ justifyContent: "flex-end" }}>
                  <NavLink to="/ad-costs">Xarajatlar</NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/ad-rewards">Mukofotlar</NavLink>
                    <NavLink className={"block"} to="/logout">
                      <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
                    </NavLink>
                  </li> */}
              </ul>
              </>
            )}
            <NavLink className={"logout"} to="/logout">
              <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
            </NavLink>
          </nav>
        </div>
      </header>
    </>
  );
}

// http://test.zarnigorbiolog.uz/v1/swagger/index.html#

export default AdNavbar;
