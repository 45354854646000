import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import moment from "moment/moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminAddTest() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [_testDate, setTestDate] = useState("");
  const [_maxScore, setMaxScore] = useState("");
  const [_testInfo, setTestInfo] = useState("");

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      date: moment(_testDate).format("MM/DD/YYYY"),
      max_point: Number(_maxScore),
      name: _testInfo,
    };

    axios
      .post("/base-test", formData, config)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setDisabled(!(_testDate && _maxScore > 0 && _testInfo)); // Shartlarni to'g'rilash
  }, [_testDate, _maxScore, _testInfo]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <TextField
                required
                label="Test haqida"
                style={{ width: "100%" }}
                value={_testInfo}
                onChange={(e) => setTestInfo(e.target.value)}
              />
              <TextField
                required
                label="Maximum ball"
                style={{ width: "100%" }}
                type="number"
                value={_maxScore}
                onChange={(e) => setMaxScore(e.target.value)}
                // error={_maxScore <= 0}
                // helperText={_maxScore <= 0 ? "Ballni to'g'ri kiriting" : ""}
              />
              <TextField
                required
                label="Vaqti"
                focused
                style={{ width: "100%" }}
                type="date"
                value={_testDate}
                onChange={(e) => setTestDate(e.target.value)}
              />
              <Button
                variant="contained"
                style={{ width: "100%" }}
                disabled={disabled}
                onClick={createStudent}
                // type="submit"
              >
                Create Test
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
