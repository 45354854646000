import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import "./Statistics.scss";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Footer/Footer";
import axios from "../../lib/axios";

function Statistics() {
  const [stats, setStats] = useState({ monthly: 100, weekly: 50000, yearly: 999 });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("role") === "admin") {
      navigate("/ad-students");
      return;
    }

    axios
      .get("/total-stats/" + localStorage.getItem("guid"), {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        setStats(response.data);
      })
      .catch((error) => {
        console.error("Ma'lumot olishda xatolik:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate]);

  // Animatsiya boshlanganda qiymat 0 dan oshib boradi
  const animatedWeekly = useSpring({ number: stats.weekly, from: { number: 0 }, reset: !loading });
  const animatedMonthly = useSpring({ number: stats.monthly, from: { number: 0 }, reset: !loading });
  const animatedYearly = useSpring({ number: stats.yearly, from: { number: 0 }, reset: !loading });

  return (
    <>
      <Header />
      <Navbar />
      <main className="mainPage">
        {!loading ? <div className="container">
          <div className="statistics-container">
            <h2 className="title">Biologiya Bo`yicha Statistika</h2>
            <div className="stat-box">
              <div className="stat-item">
                <h3>Haftalik</h3>
                <animated.p>{animatedWeekly.number.to((n) => n.toFixed(0))}</animated.p>
              </div>
              <div className="stat-item">
                <h3>Oylik</h3>
                <animated.p>{animatedMonthly.number.to((n) => n.toFixed(0))}</animated.p>
              </div>
              <div className="stat-item">
                <h3>Yillik</h3>
                <animated.p>{animatedYearly.number.to((n) => n.toFixed(0))}</animated.p>
              </div>
            </div>
          </div>
        </div> : <div className="loading">Yuklanmoqda...</div>}
      </main>
    </>
  );
}

export default Statistics;
