import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import axios from "../../../../lib/axios";
import { MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdAttendanceDateStudentUpdate(data) {
  const updateData = data.data; // Student's data
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [disabled, setDisabled] = useState(true);
  const [action, setAction] = useState(updateData.action); // Student action value
  const { id } = useParams(); // attendance_id from URL params
  const [studentId] = useState(updateData.student_id);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    if (action) {
      setDisabled(false); // If action is selected, enable the update button
    } else {
      setDisabled(true); // If no action is selected, keep the button disabled
    }
  }, [action]);

  const updateTest = async(e) => {
    e.preventDefault();
    const formData = {
      attend_id: id,
      student_id: studentId,
      action,
    };

    try {
      await axios.put("/attendance", formData, config);
      window.location.reload(); // Reload the page after successful update
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div style={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
              <Select
                value={action}
                onChange={(e) => setAction(e.target.value)}
                displayEmpty
                style={{ width: "100%" }}
              >
                <MenuItem value="here">OK</MenuItem>
                <MenuItem value="ask">+</MenuItem>
                <MenuItem value="no-ask">-</MenuItem>
              </Select>

              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={updateTest}
                disabled={disabled} // Disable the button if no action is selected
              >
                Yangilash
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
