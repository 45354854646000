import React, { useEffect, useState } from "react";
import "./MyGroups.scss";
import Header from "../../components/Header/Header";
import axios from "../../lib/axios";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import Navbar from "../../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";

function MyGroups() {
  const [groups, setGroups] = useState([]);
  // const [page, setPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  // const limit = 10;
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: localStorage.getItem("accessToken"),
    },
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "admin") {
      navigate("/ad-students");
    }

    axios
      .get(`group/student-groups/${localStorage.getItem("guid")}`, config)
      .then((response) => {
        setGroups(response.data);
        // setTotalPages(response.data.count < limit ? 1 : Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const handlePageChange = (event, value) => setPage(value);

  return (
    <>
      <Header />
      <Navbar />
      <main className="mainPage">
        <section className="mygroups-section">
          <div className="container">
            <div className="mygroup">
              <div className="mygroup__table compact">
                <div className="mygroup__table-head">
                  <div>#</div>
                  <div>Fan nomi</div>
                  {/* <div>O`qituvchi</div> */}
                  <div>Narxi</div>
                </div>
                <div className="mygroup__table-body">
                  {groups && groups.length > 0 ? (
                    groups.map((item, index) => (
                      <div
                        key={index}
                        className="mygroup__table-body--item animated-item"
                      >
                        <div>{index + 1}</div>
                        <div className="group-name">
                          <Link to={`/my-groups-single/${item.group_id}`}>
                            {item.name}
                          </Link>
                        </div>
                        {/* <div>{item.teacher_name}</div> */}
                        <div className="price">{item.price}</div>
                      </div>
                    ))
                  ) : (
                    <p style={{ color: "red", textAlign: "center" }}>Ma`lumot mavjud emas</p>
                  )}
                </div>
              </div>

              {/* <Stack spacing={2} alignItems="center" marginTop="20px">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="secondary"
                  variant="outlined"
                  shape="rounded"
                />
              </Stack> */}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default MyGroups;
