import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import logo from "../../../assets/logo/logo.png";
import { useLogin } from "../../../hooks/useLogin";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Login() {
  const storage = window.localStorage;
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setEmailPassword, error] = useLogin();
  const [enter, setEnter] = useState(false);
  const [isEnter, setIsEnter] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("guid");
  localStorage.removeItem("role");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEnter) {
      setIsEnter(true);
      localStorage.setItem("login", login);
    }
    console.log(login, enter, password);
    setEmailPassword(login, password);
    setEnter((pre) => !pre);
  };

  auth &&
    useEffect(() => {
      const data = auth;
      storage.setItem("refreshToken", data.refresh_token);
      storage.setItem("accessToken", data.access_token);
      storage.setItem("guid", data.user_id);
      storage.setItem("role", data.role);
      data.role === "student" && navigate("/");
      // data.role === "teacher" && navigate("/tech-group");
      data.role === "admin" && navigate("/ad-students");
      setLogin("");
      setPassword("");
    }, [auth, enter]);

  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="login">
            <img className="logo" src={logo} />
            <form>
              <p className="error">
                {error.response != null && error.response.data.message}
              </p>
              <TextField
                type={"text"}
                placeholder="Login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                required
                className="eyeInput"
                fullWidth
              />
              <TextField
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="eyeInput"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <input
                type="button"
                className="forgot"
                onClick={handleSubmit}
                value="Kirish"
              />
              {/* <NavLink to="/forgot">Parolingizni unutdingizmi?</NavLink> */}
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
