import React, { useEffect, useState } from "react";
import "./TechLesson.scss";
import axios from "../../../lib/axios";
// import { NavLink } from "react-router-dom";
import MyModel from "../../../components/UI/MyModel/MyModel";
import TestNavbar from "../TechNavbar/TechNavbar";
import { Delete } from "@mui/icons-material";
import TechAddLesson from "./TechAddLesson";
import TechUpdateLesson from "./TechUpdateLesson";
import MyPopUp from "./MyPopUp";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Stack from "@mui/material/Stack"; // Impo
function MyGroups() {
  const useAuth = window.localStorage;
  const userId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [lesson, setLesson] = useState();
  const [change, setChange] = useState(false);
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [limit] = useState(10); // Number of items per page

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get(`groups/${userId}`, config)
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const id = localStorage.getItem("group_id");
    id &&
      axios
        .get(
          `lesson/group-lesson?gid=${id}&page=${page}&limit=${limit}&sid=${userId}`,
          config,
        )
        .then((response) => {
          setLesson(response.data.lessons);
          setTotalPages(response.data.count < limit ? 1 : Math.ceil(response.data.count / limit)); // Assuming your API returns the total number of pages
        })
        .catch((err) => {
          console.log(err);
        });
  }, [change, page]);

  const deleteLesson = (id) => {
    console.log(lesson);
    if (window.confirm("Darsni o'chirmoqchimisiz?")) {
      axios
        .delete(`lesson/${id}`, config)
        .then((response) => {
          console.log(response.data);
          setChange(!change);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log(lesson, "lesson");
  const toggleAction = (e) => {
    const actionBody = e.target.nextSibling;
    console.log(actionBody, "actionBody");
    if (actionBody.style.display === "block") {
      actionBody.style.display = "none";
    } else {
      actionBody.style.display = "block";
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state when user changes the page
  };

  return (
    <>
      <TestNavbar />
      <main className="mainPage">
        {/* {lesson === undefined ? (
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup tech-lesson">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th className="tablet">#</th>
                      <th className="tablet">Dars nomi</th>
                      <th>Dars vaqti</th>
                      <th className="margin">•••</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups && groups.map((item, index) => (
                      <tr key={index} id="margin">
                        <td className="tablet">{index + 1}</td>
                        <td className="tablet">
                          <NavLink onClick={() => handleClick(index)}>
                            {item.group_name}
                          </NavLink>
                        </td>
                        <td>{item.teacher_name}</td>
                        <td>
                          {item.start_time.split("T")[1].slice(0, -4)}-
                          {item.end_time.split("T")[1].slice(0, -4)}
                        </td>
                        <td>{item.start_date.split("T")[0]}</td>
                        <td>{item.end_date !== "" ? item.end_date.split("T")[0] : "Noma'lum"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Guruh nomi</div>
                  </div>
                  <div className="mygroup__table-body">
                    {groups && groups.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item">
                        <div className="">{index + 1}</div>
                        <div className="group-name">
                          <NavLink onClick={() => handleClick(index)}>
                            {item.group_name}
                          </NavLink>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : ( */}
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Dars nomi</th>
                      <th>Dars sana</th>
                      <th>Vazifa</th>
                      <th className="add-wrapper">
                        <span>•••</span>
                        <TechAddLesson />
                        {/* <button className="btn-add" style={{ color: "green" }}><Add/></button> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lesson &&
                      lesson.map((item, index) => (
                        <tr key={index} id={item.id}>
                          <td>{index + 1}</td>
                          <td id="lessonName">
                            <p onClick={() => setChange(!change)}>
                              {item.lesson_name}
                            </p>
                          </td>
                          <td id="lessonDate">
                            {item.lesson_date.split("T")[0]}
                          </td>
                          <td>
                            <MyModel data={item.description} />
                          </td>
                          <td className="add-wrapper">
                            <TechUpdateLesson data={item} />
                            <button
                              className="btn-delete"
                              onClick={() => deleteLesson(item.id)}
                              style={{ color: "crimson" }}
                            >
                              <Delete />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className=""><p>#</p></div>
                    <div className="lesson_title"><p>Dars nomi</p></div>
                    <TechAddLesson />
                  </div>
                  <div className="mygroup__table-body">
                    {lesson &&
                      lesson.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div className="group-index"><p>
                          {index + 1}</p></div>
                          <div className="group-name"><p>{item.lesson_name}</p></div>
                          <div>
                            <button className="btn-action"
                            onClick={ toggleAction}>•••</button>
                          <div className="action-body">
                            {<>
                              <MyPopUp data={item} />
                            <TechUpdateLesson data={item} />
                            <button
                              className="btn-delete"
                              onClick={() => deleteLesson(item.id)}
                              style={{ color: "crimson" }}
                            >
                              <Delete />
                            </button>
                            </> }
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                                {/* Pagination Component */}
                                <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </div>
          </section>
        {/* )} */}
      </main>
    </>
  );
}

export default MyGroups;
