import React from "react";
import "./Header.scss";
import logo from "../../assets/logo/logo.png";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

function Header() {
  return (
    <header className="header-section">
      <div className="container">
        <nav className="header__nav">
          <div className="logo__wrapper">
            <NavLink className="logo__link" to="/">
              <img className="header__logo" src={logo} alt="Site logo" />
            </NavLink>
          </div>

          {/* Logout tugmasi */}
          <NavLink className="logout_btn" to="/logout">
            <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
          </NavLink>
        </nav>
      </div>
    </header>
  );
}

export default Header;
