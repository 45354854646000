import React, { useEffect, useState } from "react";
import "./Payments.scss";
import Header from "../../components/Header/Header";
import axios from "../../lib/axios";
import Navbar from "../../components/Footer/Footer";

function Payments() {
  const [payments, setPayments] = useState([]);
  const [page] = useState(1); // Current page state
  const [limit] = useState(10); // Number of items per page
  // const STUDENT_ID = window.localStorage.getItem("guid");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  const TOKEN = window.localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: `${TOKEN}`,
    },
  };

  useEffect(() => {
    axios
      .get(
        year && month ? `payment/student-group?page=${page}&limit=${limit}&year=${year}&month=${month}` : year ? `payment/student-group?page=${page}&limit=${limit}&year=${year}` : `payment/student-group?page=${page}&limit=${limit}`,
        config,
      )
      .then((data) => {
        setPayments(data.data.payments);
      })
      .catch((e) => console.log(e));
  }, [page, month, year]);

  return (
    <>
      <Header />
      <main className="payments-page">
        <section className="payments-section">
          <div className="container">
            <div className="date-wrapper">
              <select
                className="date-select"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="">Yil</option>
                <option value="2026">2026</option>
                <option value="2025">2025</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
              </select>
              <select
                className="date-select"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                <option value="">Oy</option>
                <option value="1">Yanvar</option>
                <option value="2">Fevral</option>
                <option value="3">Mart</option>
                <option value="4">Aprel</option>
                <option value="5">May</option>
                <option value="6">Iyun</option>
                <option value="7">Iyul</option>
                <option value="8">Avgust</option>
                <option value="9">Sentyabr</option>
                <option value="10">Octobr</option>
                <option value="11">Noyabr</option>
                <option value="12">Dekabr</option>
              </select>
            </div>
            <h2 className="payments-title">To`lovlar tarixi</h2>
            <div className="payments-list">
              {payments && payments.length > 0 ? (
                payments.map((payment, index) => (
                  <div
                    key={index}
                    className={`payment-card ${
                      payment.pay >= payment.need ? "paid" : "unpaid"
                    } ${payment.discount > 0 ? "discounted" : ""}`}
                  >
                    <div className="payment-info">
                      <h3>{payment.year} yil, {payment.month}-oy</h3>
                      <p>To`lanishi kerak: {payment.need} so`m</p>
                      <p>To`langan: {payment.pay} so`m</p>
                      <p>Chegirma: {payment.discount} so`m</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>To`lovlar ma`lumotlari mavjud emas</p>
              )}
            </div>
          </div>
        </section>
      </main>
      <Navbar />
    </>
  );
}

export default Payments;
