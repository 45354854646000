import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminUpdateGroup(item) {
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [disabled, setDisabled] = useState(true);
  const [groupId] = useState(item.data.group_id); // group_id ni item dan olish
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const updateGroup = (e) => {
    e.preventDefault();
    const formData = {
      group_id: groupId,
      name,
      price: parseFloat(price), // Narxni raqamga aylantirish
    };

    axios
      .put("/group", formData, config)
      .then((response) => {
        window.location.reload(); // Sahifani qayta yuklash
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setName(item.data.name);
    setPrice(item.data.price);
  }, []);

  useEffect(() => {
    if (name && price > 0 && !isNaN(price) && groupId) {
      setDisabled(false); // Ma`lumotlar to'g'ri kiritilganda tugma aktiv bo'ladi
    } else {
      setDisabled(true); // Agar Ma`lumotlar to'g'ri kiritilmagan bo'lsa, tugma deaktiv
    }
  }, [name, price, groupId]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
              <TextField
                required
                label="Guruh nomi"
                style={{ width: "100%" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!name} // Nomi bo'sh bo'lmasligi kerak
                helperText={!name ? "Guruh nomini kiritish shart" : ""}
              />
              <TextField
                required
                label="Narx"
                type="number"
                style={{ width: "100%" }}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                error={price <= 0 || isNaN(price)} // Narx noto'g'ri bo'lsa xato ko'rsatish
                helperText={price <= 0 || isNaN(price) ? "Narx to'g'ri bo'lishi kerak" : ""}
              />
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={updateGroup}
                disabled={disabled} // Tugma faqat Ma`lumotlar to'g'ri bo'lganda aktiv bo'ladi
              >
                Guruhni yangilash
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
