import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import "./AdStudent.scss";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function TechAddModal() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [_firstName, setFirstName] = useState("");
  const [_lastName, setLastName] = useState("");
  const [_phoneNumber, setPhoneNumber] = useState("");
  const [disabled, setDisabled] = useState(true); // Tugmani boshqarish uchun holat
  const { width } = useWindowDimensions();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      first_name: _firstName,
      last_name: _lastName,
      phone_number: "998" + _phoneNumber,
    };

    axios
      .post("/user", formData, config)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Ma`lumotlar to'liq kiritilganini tekshirish
  useEffect(() => {
    if (_firstName && _lastName && _phoneNumber.length === 9) {
      console.log("Ma`lumotlar to'liq kiritildi");
      setDisabled(false); // Ma`lumotlar to'g'ri bo'lsa, tugma aktiv bo'ladi
    } else {
      // console.log("Ma`lumotlar to'liq kiritildi");
      setDisabled(true); // Aks holda tugma deaktiv bo'ladi
    }
  }, [_firstName, _lastName, _phoneNumber]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              onSubmit={createStudent}
              style={ width < 500 ? { display: "flex", flexDirection: "column", rowGap: "20px" } : { display: "flex", flexDirection: "column", rowGap: "20px" }
              }
            >
              <TextField
                required
                label="Ism"
                style={width < 500 ? { width: "100%" } : { width: "100%" }}
                value={_firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={!_firstName} // Xato holatini ko'rsatish
                helperText={!_firstName ? "Ismni kiriting" : ""}
              />
              <TextField
                required
                label="Familiya"
                style={width < 500 ? { width: "100%" } : { width: "100%" }}
                value={_lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={!_lastName} // Xato holatini ko'rsatish
                helperText={!_lastName ? "Familiyani kiriting" : ""}
              />
              <TextField
                required
                label="Telefon raqam"
                placeholder="916800819"
                style={width < 500 ? { width: "100%" } : { width: "100%" }}
                value={_phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={_phoneNumber.length !== 9 || isNaN(_phoneNumber)} // Telefon raqami noto'g'ri bo'lsa xato holati
                helperText={(_phoneNumber.length !== 9) || isNaN(_phoneNumber) ? ("Telefon raqami 9ta raqamdan iborat bo'lishi kerak") : ("")}
              />
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={createStudent}
                disabled={disabled} // Tugma to'liq Ma`lumotlar kiritilgandan keyin aktiv bo'ladi
              >
                O`quvchi yaratish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
