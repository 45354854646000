import React, { useEffect, useState } from "react";
import "./TestResult.scss";
import axios from "../../lib/axios";
import ModalTest from "./ModalTest";

function TestResult() {
  const useAuth = window.localStorage;
  const userId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [data, setData] = useState([]);
  const [groupId, setGroupId] = useState("");
  console.log(userId);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get(`group/student-groups/${userId}`, config)
      .then((response) => {
        console.log(response.data[0].group_id, "group_id");
        setGroupId(response.data[0].group_id);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(groupId, "group_id");
    axios
      .get(`group-test/student-result?sid=${userId}&gid=${groupId}&page=1&limit=1`, config)
      .then((response) => {
        setData(response.data.tests);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(data);
  return (
    <>
      <section className="mygroup-section">
        <div className="container">
          <div className="mygroup">
            <table className="mygroup__table desktop">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Test Sanasi</th>
                  <th>Eng yuqori ball</th>
                  <th>Sizning balingiz</th>
                  <th>Foizlarda</th>
                  <th>Ma`lumot</th>
                </tr>
              </thead>
              {data && data.length > 0 && (
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.test_date.split("T")[0]}</td>
                      <td>{item.max_score}</td>
                      <td>{item.student_score}</td>
                      <td>{item.student_percent.toFixed(2)}</td>
                      <td>{item.test_info}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>

            <div className="mygroup__table mobile">
                <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Test sanasi</div>
                    <div></div>
                </div>
                <div className="mygroup__table-body">
                  {data && data.map((item, index) => (
                    <div key={index} className="mygroup__table-body--item">
                      <div style={{ padding: "23px" }} className="">{index + 1}</div>
                      <div style={{ padding: "23px" }} className="group-name">
                        {/* {item.test_date.split("T")[0]} */}
                      </div>
                      <div><ModalTest data={item}/></div>
                    </div>
                  ))}
                </div>
              </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
}

export default TestResult;
