import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "../../../../lib/axios";
import { KeyboardReturn } from "@mui/icons-material";
import AdAttendanceDateStudentUpdate from "./AdAttendanceDateStudentUpdate";
// import moment from "moment";

export default function AdAttendanceDateStudent() {
  const useAuth = window.localStorage;
  const { id } = useParams();
  const Token = useAuth.getItem("accessToken");
  const [students, setStudents] = useState([]);
  const [attendanceId] = useState(id);
  const [groupId] = useState(localStorage.getItem("groupId"));

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get("attendance/" + attendanceId, config)
      .then((response) => {
        console.log(response.data.actions);
        setStudents(response.data.actions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <>
      <section className="admin-single-group-section AdAttendanceDateStudent">
        <div className="container">
          <div className="admin-single adAttendanceAddStudent">
            <section className="mygroup-section tech-lesson-section admin-group-section">
              <div className="wrapper-date">
              <Link
                  to={"/ad-groups-attendance/" + groupId}
                  className=""
                >
                  <Button>
                  <KeyboardReturn />
                  </Button>
                </Link>
              </div>

              <div className="mygroup">
                    <table className="mygroup__table desktop">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Talabalar</th>
                          <th>Telefon raqam</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {students && students.length > 0 ? (
                          <>
                                {students.map((item, index) => (
                                  <tr key={item.user_id}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <p style={item.action === "ask" ? { color: "green" } : item.action === "no-ask" ? { color: "red" } : { color: "black" }}>
                                      {item.first_name} {item.last_name}
                                      </p>
                                    </td>
                                    <td>+{item.phone_number}</td>
                                    <td>
                                      {item.action === "here" ? "Ok" : item.action === "ask" ? "+" : "-"}
                                    </td>
                                    <td>
                                      <AdAttendanceDateStudentUpdate data={item}/>
                                    </td>
                                  </tr>
                                ))}
                          </>
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                              color: "red",
                              marginTop: "20px",
                            }}
                          >
                            Ma`lumotlar mavjud emas
                          </p>
                        )}
                    </tbody>
                  </table>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
}
