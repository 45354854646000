import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, MenuItem, Select } from "@mui/material";
import axios from "../../../../lib/axios";
import { Add, KeyboardReturn } from "@mui/icons-material";
import moment from "moment";

export default function AdAttendanceAddStudent() {
  const useAuth = window.localStorage;
  const { id: groupID } = useParams();
  const Token = useAuth.getItem("accessToken");
  const [students, setStudents] = useState([]);
  const [actionsState, setActionsState] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]); // Current date
  const [groupId] = useState(groupID);
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get("group/group-students/" + groupID, config)
      .then((response) => {
        console.log(response.data);
        setStudents(response.data);
        // Har bir talaba uchun default "ok" action holatini o'rnatamiz
        const initialActionsState = response.data.map((student) => ({
          student_id: student.user_id,
          action: "here", // Default action "ok"
        }));
        console.log(initialActionsState);
        setActionsState(initialActionsState);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [groupID]);

  const handleActionChange = (studentId, action) => {
    setActionsState((prevState) =>
      prevState.map((student) =>
        student.student_id === studentId ? { ...student, action } : student,
      ),
    );
  };

  const handleSubmit = () => {
    const dataToSend = {
      actions: actionsState, // All actions with their respective student IDs
      date: moment(date).format("MM/DD/YYYY"),
      group_id: groupId,
    };

    console.log(dataToSend);

    axios
      .post("/attendance", dataToSend, config)
      .then(() => {
        alert("Ma`lumotlar saqlandi");
        navigate("/ad-groups-attendance/" + groupID);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section className="admin-single-group-section">
        <div className="container">
          <div className="admin-single adAttendanceAddStudent">
            <section className="mygroup-section tech-lesson-section admin-group-section">
              <div className="wrapper-date">
              <div className="date-input-wrapper">
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="admin-group-section__noBorder date-input"
                />
              </div>
              <Link
                  to={"/ad-groups-attendance/" + groupId}
                  className=""
                >
                  <Button>
                  <KeyboardReturn />
                  </Button>
                </Link>
              </div>

              <div className="mygroup">
                {students.length > 0 ? (
                  <>
                    <table className="mygroup__table desktop">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Talabalar</th>
                          <th>Telefon raqam</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {students.map((item, index) => (
                          <tr key={item.user_id}>
                            <td>{index + 1}</td>
                            <td>
                              {item.first_name} {item.last_name}
                            </td>
                            <td>+{item.phone_number}</td>
                            <td>
                              <Select
                                value={
                                  actionsState.find(
                                    (action) =>
                                      action.student_id === item.user_id,
                                  )?.action || "here" // Default "ok" if not set
                                }
                                onChange={(e) =>
                                  handleActionChange(
                                    item.user_id,
                                    e.target.value,
                                  )
                                }
                                displayEmpty
                              >
                                <MenuItem value="here">OK</MenuItem>
                                <MenuItem value="ask">+</MenuItem>
                                <MenuItem value="no-ask">-</MenuItem>
                              </Select>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="mygroup__btn">
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        startIcon={<Add />}
                      >
                        Saqlash
                      </Button>
                    </div>
                  </>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      color: "red",
                      marginTop: "20px",
                    }}
                  >
                    Ma`lumotlar mavjud emas
                  </p>
                )}
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
}
