import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../../lib/axios";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
// import moment from "moment";
// import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminAddStudentToTest() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [studentId, setStudentId] = useState("");
  const [students, setStudents] = useState([]);
  // const [student, setStudent] = useState({});
  const [studentScore, setStudentScore] = useState();
  const { id } = useParams();
  const [test, setTest] = useState([]);
  const navigate = useNavigate();
  // const [date] = useState(new Date());
  const [testId, setTestId] = useState("");

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios.get("/user/select", config).then((response) => {
      setStudents(response.data);
      console.log(students);
    });

    axios.get(`/group-test/${id}`, config).then((response) => {
      console.log(response.data, response.data.max_score);
      setTest(response.data);
      setTestId(response.data.group_test_id);
      console.log(test);
    }).catch((err) => {
      if (err.response.status === 401) {
        navigate("/logout");
      }
      console.log(err);
    });
  }, []);

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      group_test_id: testId,
      student_id: studentId,
      student_point: studentScore - 0
    };

    console.log(formData);

    axios
      .put("/group-test/student-point", formData, config)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getStudentInfo = () => {
  //   axios.get(`/student/${studentId}`, config).then((response) => {
  //     setStudent(response.data);
  //   });
  // };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
              <TextField
                required
                style={{ width: "100%" }}
                value={studentId}
                onChange={(e) => {
                  setStudentId(e.target.value);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>Stundent tanlang</option>
                {students &&
                  students.map((item) => (
                    <option key={item.student_id} value={item.student_id}>
                      {item.name}
                    </option>
                  ))}
              </TextField>
              <TextField
                required
                style={{ width: "100%" }}
                value={studentScore}
                onChange={(e) => {
                  setStudentScore(Number(e.target.value));
                  // getStudentInfo();
                }}
                label="Score"
                type="text"
              />
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={createStudent}
              >
                Qo`shish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

// {
//   "student_id": "72aa9bc2-e65b-4453-a8da-8664c1401f1f",
//   "max_score": 80,
//   "student_score": 100,
//   "student_first_name": "Tohirjon",
//   "student_last_name": "Odilov",
//   "student_percent": 20,
//   "test_date": "2024-07-24T00:00:00Z",
//   "test_id": "377a5ce3-a9cd-42c2-ab84-e33bca126d2f",
//   "test_info": "Matem test",
// }
