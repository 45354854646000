import React, { useEffect, useState } from "react";
import "./AdAttendance.scss";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../../lib/axios";
import AdGroupNavbar from "../AdGroupNavbar/AdGroupNavbar";

function AdAttendance() {
  const useAuth = window.localStorage;
  const Token = useAuth.getItem("accessToken");
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groupId] = useState(localStorage.getItem("groupId"));

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    const id = localStorage.getItem("groupId");
    if (id) {
      axios
        .get(`attendances?id=${groupId}&page=1&limit=12`, config)
        .then((response) => {
          setAttendance(response.data.attends || []);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [groupId]);

  return (
    <>
      <AdGroupNavbar />
      <main className="mainPage adAttendanceAddStudent-main">
        <section className="mygroup-section tech-lesson-section">
          <div className="container">
            <div className="mygroup tech-lesson">
                <>
                  <table className="mygroup__table desktop">
                    <thead>
                      <tr>
                        <th className="tablet">#</th>
                        <th className="tablet">Date</th>
                        <th style={{ width: "200px" }} className="add-fixed">
                          <NavLink
                            style={{ display: "flex" }}
                            to={"/ad-groups-attendance-student/" + groupId}
                          >
                            <Button>
                              <Add />
                            </Button>
                          </NavLink>
                        </th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr><td colSpan={3} style={{ textAlign: "center" }}>Yuklanmoqda..</td>.</tr>
                      ) : attendance.length === 0 ? (
                        <tr><td colSpan={3} style={{ textAlign: "center" }}>Ma`lumot mavjud emas</td></tr>
                      ) : (
                          <>
                          {attendance.map((item, index) => (
                                <tr key={index} id={item.id}>
                                  <td className="tablet">{index + 1}</td>
                                  <td className="tablet"><NavLink to={`/ad-groups-attendance-date-student/${item.attendance_id}`}>{item.date.split("T")[0]}</NavLink></td>
                                  <td></td>
                                </tr>
                          ))}
                          </>
                      )}
                    </tbody>
                  </table>

                  <div className="mygroup__table mobile tech-attendance-mobile">
                    <div className="mygroup__table-head">
                      <div className="">#</div>
                      <div className="">Sana</div>
                    </div>
                    <div className="mygroup__table-body">
                      {attendance.map((item, index) => (
                        <div
                          key={index}
                          id={item.id}
                          className="mygroup__table-body--item"
                        >
                          <div className="">{index + 1}</div>
                          <div className="group-name">{item.date}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AdAttendance;
