import React, { useEffect, useState } from "react";
import "./Profile.scss";
import Header from "../../components/Header/Header";
import men from "../../assets/logo/men.png";
import women from "../../assets/logo/women.png";
import axios from "../../lib/axios";
import Navbar from "../../components/Footer/Footer";

function Profile() {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);

  const TOKEN = window.localStorage.getItem("accessToken");
  const USER_ID = window.localStorage.getItem("guid");
  const LOGIN = window.localStorage.getItem("login");
  const role = window.localStorage.getItem("role");

  const config = {
    headers: {
      Authorization: `${TOKEN}`,
    },
  };

  useEffect(() => {
    axios
      .get(
        role === "teacher" ? `https://erp.xasannosirov.uz/v1/teacher/${USER_ID}` : `https://erp.xasannosirov.uz/v1/user/${USER_ID}`,
        config
      )
      .then((data) => {
        setResponse(data.data);
        localStorage.setItem("user_name", `${data.data.first_name} ${data.data.last_name}`);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div className="loader">Yuklanmoqda...</div>; // Loading animation
  }

  if (!response) {
    return <p className="no-data">Ma`lumot mavjud emas</p>;
  }

  return (
    <>
      <Header />
      <main>
        <section className="profile-section">
          <div className="container">
            <div className="profile-card">
              <div className="profile-header flex">
                <img src={response.gender === "female" ? women : men} alt="user-avatar" className="profile-img" />
                <div className="user-info">
                  <h2>{response.first_name} {response.last_name}</h2>
                  <p className="role">{role === "teacher" ? "O'qituvchi" : "Talaba"}</p>
                </div>
              </div>
              <div className="profile-details">
                <h3>Shaxsiy ma`lumotlar</h3>
                <div className="details-grid">
                  <div className="detail-item">
                    <strong>Ism</strong>
                    <p>{response.first_name}</p>
                  </div>
                  <div className="detail-item">
                    <strong>Familiya</strong>
                    <p>{response.last_name}</p>
                  </div>
                  <div className="detail-item">
                    <strong>Login</strong>
                    <p>{LOGIN}</p>
                  </div>
                  <div className="detail-item">
                    <strong>Telefon raqam</strong>
                    <p>+{response.phone_number}</p>
                  </div>
                  <div className="detail-item">
                    <strong>Parol</strong>
                    <p>{response.phone_number.slice(8)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Navbar />
    </>
  );
}

export default Profile;
