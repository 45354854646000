import React from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Footer/Footer";
function MyGroupsSingle() {
  const { groupId } = useParams();

  return (
    <>
     <Header />
     <main>
     <section className="section-myGroupsSingle">
      <div className="container">
       <div className="myGroupsSingle">
        <div className="myGroupsSingle__wrapper">
         {/* <h2 className="myGroupsSingle__title">MyGroup</h2> */}
         <ul className="myGroupsSingle__list">
          <li className="myGroupsSingle__item">
           <Link to={"/my-groups-attends/" + groupId} className="myGroupsSingle__link">
            Davomat
           </Link>
          </li>
          <li className="myGroupsSingle__item">
           <Link to={"/my-groups-tests/" + groupId} className="myGroupsSingle__link">
            Test natijalari
           </Link>
          </li>
         </ul>
        </div>
        </div>
       </div>
     </section>
     </main>
     <Navbar/>
    </>
  );
}

export default MyGroupsSingle;
